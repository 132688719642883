
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

    @Component
    export default class Button extends Vue {
        @Prop() private icon?: string;
        @Prop() private state?: string;
        @Prop({default:false}) private disabled?: boolean;

        get styleClass(): string {
            let classes = '';
            if(this.icon) classes += ' button--has-icon';
            if(this.state === 'alert') classes += ' alert';
            return classes;
        }

        @Emit("click")
        public onClick(): void {
            return;
        }
    }
